// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-book-a-cpd-jsx": () => import("./../../../src/pages/contact/book-a-cpd.jsx" /* webpackChunkName: "component---src-pages-contact-book-a-cpd-jsx" */),
  "component---src-pages-contact-general-questions-jsx": () => import("./../../../src/pages/contact/general-questions.jsx" /* webpackChunkName: "component---src-pages-contact-general-questions-jsx" */),
  "component---src-pages-contact-sertus-gift-subscription-jsx": () => import("./../../../src/pages/contact/sertus-gift-subscription.jsx" /* webpackChunkName: "component---src-pages-contact-sertus-gift-subscription-jsx" */),
  "component---src-pages-contact-site-support-jsx": () => import("./../../../src/pages/contact/site-support.jsx" /* webpackChunkName: "component---src-pages-contact-site-support-jsx" */),
  "component---src-pages-contact-start-project-jsx": () => import("./../../../src/pages/contact/start-project.jsx" /* webpackChunkName: "component---src-pages-contact-start-project-jsx" */),
  "component---src-pages-editor-jsx": () => import("./../../../src/pages/editor.jsx" /* webpackChunkName: "component---src-pages-editor-jsx" */),
  "component---src-pages-project-specification-calculation-jsx": () => import("./../../../src/pages/project-specification-calculation.jsx" /* webpackChunkName: "component---src-pages-project-specification-calculation-jsx" */),
  "component---src-templates-cad-viewer-jsx": () => import("./../../../src/templates/cad-viewer.jsx" /* webpackChunkName: "component---src-templates-cad-viewer-jsx" */),
  "component---src-templates-storyblok-page-jsx": () => import("./../../../src/templates/storyblok-page.jsx" /* webpackChunkName: "component---src-templates-storyblok-page-jsx" */)
}

